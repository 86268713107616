<template>
    <div class="container">
		<!-- 设置登录密码 -->
		<common-head url="/securityCenter" :title="$t('tba.changeLoginPass')" :is-return="true"></common-head>
		
		<div class="layoutBox bgWhite body1Style">
			<p class="body1StylepFont" style="margin-bottom: .5rem;">
				<span class="mainFontLightColor">{{ $t('tba.codeHasSend') }}</span>
				<strong class="mainFontColor">+{{ nation }} {{ phone }}</strong>
				<!-- <strong class="mainFontColor" v-if="vcode_method == 'phone'">+{{ nation }} {{ phone }}</strong> -->
				<!-- <strong class="mainFontColor" v-if="vcode_method == 'email'">{{ email }}</strong> -->
			</p>
		
			<form-list :label="$t('sPhone.messageAuthenticationCode')">
				<div class="bd" slot="contslot">
					<input type="number" class="inp" v-model="inputCode" :placeholder="$t('login.PleaseEnterVerificationCode')" />
					<div class="button" @click="onSendCode">
						<span v-if="codeSending" class="mainColor1">({{ theTime }})</span>
						<span v-else class="mainColor1">{{ $t('sPhone.getCode') }}</span>
					</div>
				</div>
			</form-list>
			<form-list :label="$t('tba.inputLoginPwd')">
				<div class="bd" slot="contslot">
					<input type="password" class="inp" v-model="password" :placeholder="$t('tba.inputLoginPwd')" />
				</div>
			</form-list>
			<form-list :label="$t('sPwd.pleaseAffirmPassword')">
				<div class="bd" slot="contslot">
					<input type="password" class="inp" v-model="password2" :placeholder="$t('sPwd.pleaseAffirmPassword')" />
				</div>
			</form-list>
			
			<div class="comBtnStyle" @click="bindPwd" style="margin-top: 5rem;">{{ $t('tba.changePwd') }}</div>
		</div>
		
    </div>
</template>

<script>
	import commonHead from '../../components/commonHead';
	import formList from '../../components/formList';
    export default {
        name: "setThePassword",
        components: {
			commonHead,
			formList,
		},
        data() {
            return {
                value: "",
                phone: "", //  用户手机号
                nation: "", // 国家区号
                // popupShow: false,
                // popupShow2: false,
                // popupShow3: false,
                showKeyboard: false,
                exchange_name: "",
                email: "",
                inputCode: "",
                exchange_id: 0,
                article_id: 0,
                password: "", //交易密码
                password2: "",
                count: 0,
                list2: {},
                flag: false,
                ifhave: false,
                theTime: 60,
                vcode_method: "",
                show: false,
                paypwd: "",
                codeSending: false,
                paypwd2: "",
            };
        },
        created() {
            this.exchange_name = this.$route.query.exchange_name;
            this.exchange_id = this.$route.query.exchange_id;
            // this.getKey();
            this.initData();
            // this.getKey();
        },

        methods: {
            initData() {
                this.$post2("user/api/index/getMetaInfo", {})
                    .then((res) => {
                        this.phone = res.phone;
                        this.nation = res.nation;
                    })
                    .catch((e) => {
                        this.$toast.fail(e);
                    });
            },
            bindPwd() {
                if (!this.check()) {
                    return;
                }
                this.doSetpwd();
            },
			// 设置新密码
            doSetpwd() {
                this.$post2("/user/api/index/resetpwd", {
                    password: this.password,
					repassword: this.password2,
                    code: this.inputCode,
                    sid: this.sid,
                }).then((res) => {
                    console.log(res);
                    this.$toast(this.$t("sPwd.setPasswordSuccess"));
                    this.$router.go(-1);
                });
            },

            // 获取验证码
            onSendCode() {
                if (this.codeSending) {
                    return;
                }
                this.theTime = 60;
                this.codeSending = true;
                this.doCountDown();
                // this.$post2('/security/user/authsmscode', {
                //     op: "opsecret",
                // }).then((res) => {
                //     console.log(res);
                //     this.sid = res.sid;
                // });
				this.$post2('/security/user/authsmscode', {
					phone: '15037915292',
					nation: '86',
					op: 'opsecret',
				}).then(res => {
					// console.log(res);
					this.sid = res.sid;
				});
            },
            // 又改成 一次定时器了 写的真烂...
            doCountDown() {
                if (this.theTime > 1) {
                    this.theTime--;
                    this.codeTimer = setTimeout(() => {
                        this.doCountDown();
                    }, 1000);
                } else {
                    this.codeSending = false;
                }
            },
            check() {
                if (this.inputCode == "") {
                    this.$toast(this.$t("sPay.PleaseEnterCorrectVerificationCode"));
                    return false;
                }
                // if (this.loginpwd == "") {
                //   this.showAlert("请输入正确的登录密码");
                //   return false;
                // }
                if (this.password == "") {
                    this.$toast(this.$t("sPay.PleaseEnterCorrectTransactionPassword"));
                    return false;
                }
                if (this.password !== this.password2) {
                    this.$toast(this.$t("sPay.twoPasswordsDoNotMatch"));
                    return false;
                }

                return true;
            },
            onClickRight() {
                this.$router.push({
                    path: "viewArticle",
                    query: {
                        id: this.article_id,
                    },
                });
            },
            onClickLeft() {
                this.$router.push("/uSet");
            },
            onCancel() {
                Toast("取消");
            },
            btnChange() {
                this.show = true;
            },
        },
    };
</script>

<style scoped>
    .imgFontStyle {
        width: 0.4rem;
    }

    .divTopInChong {
        margin-top: 1.4rem;
    }

    .body1StyleDivBtn {
        border: 0px;
        border-radius: 5px;
        background: #e1c364;
        width: 2rem;
        margin-top: 0.3rem;
    }

    .body1StyleDivStyle {
        margin-top: 0.6rem;
    }

    .body1StyleSPan {
        color: #FF4B04;
    }

    .body1StylepFont {
        margin-top: 0.3rem;
        font-size: 0.3rem;
    }

    /* @import "../../assets/css/puliceStyle.css"; */
    .body1StyleInputStyle {
        border: 0px;
        width: 60%;
    }

    .bodyStyleDivStyleP {
        margin-top: 0.3rem;
        margin-bottom: 0.3rem;
    }

    .bodyStyleDivStyle {
        border-bottom: 1px solid #fbfbfd;
        padding-bottom: 0.3rem;
    }

    .body1Style {
        padding: 0.3rem;
    }

    .bottom1 {
        margin-top: 2rem;
    }

    .whole {
        background: #ffffff;
    }

    .title {
        background: #f6f8f9;
        height: 2rem;
        padding: 0.3rem;
    }

    .bottomBtnStyle {
        width: 100%;
        background: #e1c364;
        border-radius: 5px;
        border: 0px;
    }

    .titleGuanLiStyle {
        font-size: 0.4rem;
        padding-top: 1.5rem;
    }

    .titlePStyle {
        margin-bottom: 0.2rem;
        margin-top: 0.3rem;
        padding-top: 0.1rem;
        transform: rotate(180deg);
    }
</style>
